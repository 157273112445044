<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link justify-content-end"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :src="userAvatar"
        variant="light-primary"
        class="badge-minimal"
        badge
        badge-variant="success"
      >
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'auth-update-profile-account'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {}
  },
  computed: {
    userName() {
      const full_name = this.getValueFromSource(this.currentUser, 'full_name');
      return this.sentenceCase(full_name);
    },
    userAvatar() {
      const image_path = this.getValueFromSource(this.currentUser, 'avatar.path');
      return image_path
    }
  },
  methods: {},
}
</script>
