<template>
  <div class="">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <b-link
              class="nav-link d-xl-none"
              @click="toggleVerticalMenuActive"
          >
            <feather-icon
                icon="MenuIcon"
                size="21"
            />
          </b-link>
        </li>
        <!--        <li class="nav-item ">-->
        <!--          &lt;!&ndash; App logo for mobile view &ndash;&gt;-->
        <!--          <turtle-rent-logo width="150" />-->
        <!--        </li>-->

        <li class="nav-item d-none d-sm-block">
          <!-- make this a shared component which will be from the route meta -->
          <div class="d-flex align-items-center">
            <h3 style="color: #011151;">
              {{ getValueFromSource($route, 'meta.pageTitle', '') }}</h3>
          </div>
        </li>
      </ul>

      <!-- Left Col -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

        <!-- Bookmarks Container -->
        <!-- <bookmarks /> -->
      </div>

      <b-navbar-nav class="nav align-items-center ml-auto">
        <h3 
          v-if="currentUser.user_type === 'church'"
          class="d-none d-lg-flex m-0 font-weight-bold mr-2"
        >
          Church Of Pentecost - {{ getChurchName }}
        </h3>

        <!-- <locale /> -->
        <dark-toggler />
        <notification-dropdown />
        <user-dropdown />
      </b-navbar-nav>
    </div>

    <app-breadcrumb v-if="(y === 0) || (y > 0 && y <= 27)" />
  </div>
</template>

<script>
import {
  BLink, BAvatar, BNavbarNav,
} from 'bootstrap-vue'
import { useWindowScroll } from '@vueuse/core'

import TurtleRentLogo from '@core/layouts/components/Logo.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'


import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'


export default {
  components: {
    TurtleRentLogo,
    BLink,
    BAvatar,
    AppBreadcrumb,

    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    getChurchName() {
      const { church_profile } = this.currentUser
      if (church_profile){
        return this.getChurchLocationByChurchLevel(church_profile.church_level, church_profile)
      }

      return '';
    }
  },
  setup() {
    const { y } = useWindowScroll()
    return { y }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>
