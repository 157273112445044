export default [
  {
    title: 'Welcome',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "admin-home",
    action: "read",
  },
  {
    header: 'Admins & Users',
    permissions: [
      "admin:CanList",
      "users:CanList",
      "church_profile:CanList"
    ]
  },
  {
    title: 'Administrators',
    icon: 'UserPlusIcon',
    tagVariant: 'light-warning',
    route: "admin",
    action: "read",
    permissions: ["admin:CanList"],
    childrenNestedRoutes: [
      'admin-new-user'
    ]
  },
  {
    title: 'Churches',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    route: "admin-churches",
    action: "read",
    permissions: ["church_profile:CanList"],
  },
  {
    title: 'Members',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    route: "admin-users",
    action: "read",
    permissions: ["users:CanList"],
  },
  {
    header: 'Offerings & Settlements',
    permissions: [
      "pre_transactions:CanList",
      "settlements:CanList",
      "offering_pledge:CanList"
    ]
  },
  {
    title: 'Offerings',
    icon: 'TrendingUpIcon',
    tagVariant: 'light-warning',
    route: "admin-transactions",
    action: "read",
    permissions: ["pre_transactions:CanList"],
  },
  {
    title: 'Generate Report',
    icon: 'TrendingUpIcon',
    tagVariant: 'light-warning',
    route: "generate-offerings-report",
    action: "read",
    permissions: ["pre_transactions:CanList"],
  },
  {
    title: 'Settlements',
    icon: 'TrendingUpIcon',
    tagVariant: 'light-warning',
    route: "admin-settlements",
    action: "read",
    permissions: ["settlements:CanList"],
  },
  {
    title: 'Recurring Pledge',
    icon: 'CropIcon',
    tagVariant: 'light-warning',
    route: "admin-recurring-offerings",
    action: "read",
    permissions: ["offering_pledge:CanList"],
  },
  {
    header: 'App Settings',
    permissions: [
      "admin-offering-types",
      "settings:CanList",
      "settings:CanUpdate",
      "policy:CanList",
      "policy:CanView",
      "user_role:CanList",
      "resource:CanList"
    ]
  },
  {
    title: 'Church Map',
    action: "read",
    route: "admin-church-map",
    icon: 'CastIcon',
    tagVariant: 'light-warning',
    permissions: ["church_map:CanList"],
    childrenNestedRoutes: []
  },
  {
    title: 'Offering Types',
    icon: 'ListIcon',
    permissions: ["offering_types:CanList"],
    route: "admin-offering-types",
    action: "read",
    childrenNestedRoutes: [
      'admin-new-offering-type',
      'admin-update-offering-type'
    ]
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'General',
        icon: 'SettingsIcon',
        tagVariant: 'light-warning',
        route: "admin-settings",
        action: "read",
        permissions: ["settings:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-new-permission',
      'admin-update-permission'
    ]
  },
  {
    title: 'Administration',
    icon: 'MinusCircleIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Resources',
        icon: 'BoxIcon',
        tagVariant: 'light-warning',
        route: "admin-resources",
        action: "read",
        permissions: ["resource:CanList"],
      },
      {
        title: 'Policies',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-policies",
        action: "read",
        permissions: ["policy:CanList"],
      },
      {
        title: 'Roles',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-user-roles",
        action: "read",
        permissions: ["user_role:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-resources',
      'admin-new-resources',
      'admin-update-resource',
      'admin-resource-actions',
      'admin-new-resource-action',
      'admin-update-resource-action',
      'admin-policies',
      'admin-new-policy',
      'admin-user-roles',
      'admin-view-policies',
      'admin-new-user-role',
      'admin-update-user-role',
    ]
  },
  {
    header: 'Others',
    permissions: [
      "faqs:CanList",
      "blogs:CanList",
      "admin_logs:CanList"
    ]
  },
  {
    title: 'Miscellaneous',
    icon: 'CpuIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Resources',
        action: "read",
        route: "admin-blogs",
        icon: 'ClipboardIcon',
        tagVariant: 'light-warning',
        permissions: ["blogs:CanList"],
      },
      {
        title: 'FAQs',
        action: "read",
        route: "admin-faqs",
        icon: 'HelpCircleIcon',
        tagVariant: 'light-warning',
        permissions: ["faqs:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-new-faq',
      'admin-new-blog',
      'admin-single-faq',
      'admin-update-faq',
      'admin-single-blog',
      'admin-update-blog',
    ]
  },
  {
    title: 'Logs',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Admin Logs',
        action: "read",
        icon: 'ListIcon',
        route: "admin-logs",
        tagVariant: 'light-warning',
        permissions: ["admin_logs:CanList"]
      },
      {
        title: 'User Logs',
        action: "read",
        icon: 'ListIcon',
        route: "user-logs",
        tagVariant: 'light-warning',
        permissions: ["logs:CanList"]
      },
      {
        title: 'Audit Trail',
        action: "read",
        route: "audit-trail",
        icon: 'GitBranchIcon',
        tagVariant: 'light-warning',
        permissions: ["audit_trail:CanList"]
      },
    ],
    childrenNestedRoutes: [
      'admin-logs',
      'user-logs',
      'audit-trail'
    ]
  },
]
