export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: "church-home",
    action: "read",
  },
  {
    title: 'IAM',
    icon: 'UsersIcon',
    route: "access-management",
    action: "read",
    church_permission: "can_view_users"
  },
  {
    title: 'Offerings',
    icon: 'ListIcon',
    route: "church-offerings",
    action: "read",
    church_permission: "can_view_offerings"
  },
  {
    title: 'Recurring Pledge',
    icon: 'CropIcon',
    action: "read",
    tagVariant: 'light-warning',
    route: "church-recurring-offerings",
    church_permission: "can_view_offerings"
  },
  {
    title: 'Generate Report',
    icon: 'TrendingUpIcon',
    tagVariant: 'light-warning',
    route: "generate-offerings-report",
    action: "read",
    church_permission: ["can_view_settlements"],
  },
  {
    title: 'Settlements',
    icon: 'DownloadIcon',
    route: "settlement",
    action: "read",
    church_permission: "can_view_settlements"
  },
  {
    title: 'Bank Details',
    icon: 'BriefcaseIcon',
    route: "bank-details",
    action: "read",
    church_permission: "can_view_bank_details"
  },
  {
    title: 'Offering Link',
    icon: 'BriefcaseIcon',
    route: "church-offering-link",
    action: "read",
  },
]
