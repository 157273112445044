export default [
  {
    title: 'Give',
    icon: 'ArchiveIcon',
    tagVariant: 'light-warning',
    route: "member-give",
    action: "read",
  },
  {
    title: 'Offerings',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    route: "member-home",
    action: "read",
  },
  {
    title: 'Recurring Offerings',
    icon: 'CropIcon',
    tagVariant: 'light-warning',
    route: "member-recurring-offering",
    action: "read",
  },
]
